/* eslint-disable */
import api from "./api";
const DriverService = {
  register: async (values: any) => {
    try {
      const data = {
        ...values,
        password: values.email,
        type: "driver",
        permission_id: 1,
        perfil: {},
        docs: {}
      };
      return (await api.post<any>(`/accounts/register`, data)).data;
    } catch (error) {
      return {
        error: "Nã foi possível processar o seu pedido."
      };
    }
  }
};

export default DriverService;

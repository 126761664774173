/* eslint-disable */
export const locale = {
  mixed: {
    default: "Não é válido",
    required: "Preencha este campo.",
    password: "A senha deve conter no máximo ${min} caracteres.",
    number: "Este campo deve conter apenas números"
  },
  string: {
    email: "Email inválido",
    required: "Este campo é obrigatório",
    min: "Este campo deve conter no mínimo ${min} caracteres",
    max: "deve ter no máximo ${max} caracteres"
  },
  number: {
    default: "Este campo deve conter apenas números",
    required: "Preencha este campo.",
    //number: "Este campo deve conter apenas números",
    min: "Deve ser maior que ${min}",
    password: "A senha deve conter no máximo ${min} caracteres."
  }
};

export const config: any = {
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true
};

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Slide',
});
</script>

<style scoped>
.video-bg {
  background-image: url('../assets/bg-slider.png');
}
</style>

<template>
  <section id="home" class="banner video-bg bottom-oval">
    <!-- Container -->
    <div class="container">
      <div class="row">
        <div class="col-md-12"></div>
      </div>

      <div class="row align-items-center">
        <!-- Content -->
        <div class="col-12 col-lg-8 offset-lg-2">
          <!-- Banner text -->
          <div class="banner-text text-center">
            <h1
              class="wow fadeInUp"
              data-wow-offset="10"
              data-wow-duration="1s"
              data-wow-delay="0s"
            >
              Viage como um boss!
            </h1>

            <p
              class="wow fadeInUp"
              data-wow-offset="10"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              O melhor aplicativo de taxi para se andar pela cidade. Baixe já o nosso App e começa a
              viajar como um boss.
            </p>

            <div
              class="button-store wow fadeInUp"
              data-wow-offset="10"
              data-wow-duration="1s"
              data-wow-delay="0.6s"
            >
              <a href="#" class="d-inline-flex align-items-center m-2 m-sm-0 mr-sm-3">
                <img src="../assets/banner/google-play.png" alt="" />
              </a>

              <a href="#" class="d-inline-flex align-items-center m-2 m-sm-0">
                <img src="../assets/banner/app-store.png" alt="" />
              </a>
            </div>
          </div>

          <div class="empty-30"></div>
        </div>
      </div>
    </div>

    <!-- Image -->
    <div
      class="banner-image-center w-100 wow fadeInUp"
      data-wow-offset="10"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    >
      <img src="../assets/features/awesome-features.png" alt="" />
    </div>
  </section>
</template>

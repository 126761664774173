<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>

<template>
  <footer>
    <!-- Widgets -->
    <div class="footer-widgets" id="footer">
      <div class="container">
        <div class="row">
          <!-- Footer logo -->
          <div class="col-12 col-md-6 col-lg-3 res-margin">
            <div class="widget">
              <h3 class="text-white">Ed-taxi</h3>
              <!-- <p class="footer-logo">
                <img src="../assets/logo-white.png" alt="Naxos" data-rjs="2" />
              </p> -->
              <p>
                Email: geral@ed-taxi.com<br />
                Telefone: 900 000 000
              </p>

              <!-- Social links -->
              <div class="footer-social">
                <a href="#" title="Twitter"><i class="fab fa-twitter fa-fw"></i></a>
                <a href="#" title="Facebook"><i class="fab fa-facebook-f fa-fw"></i></a>
                <a href="#" title="Instagram"><i class="fab fa-instagram"></i></a>
                <!-- <a href="#" title="Dribbble"><i class="fab fa-dribbble"></i></a>
                <a href="#" title="Pinterest"><i class="fab fa-pinterest fa-fw"></i></a> -->
              </div>
            </div>
          </div>

          <!-- Useful links -->
          <div class="col-12 col-md-6 col-lg-3 offset-lg-1 res-margin">
            <div class="widget">
              <h6>Links Úteis</h6>

              <ul class="footer-menu">
                <li><a href="#">Politicas</a></li>
                <li><a href="#">Termos &amp; Condições</a></li>
                <!-- <li><a href="#">Careers</a></li> -->
              </ul>
            </div>
          </div>

          <!-- Product help -->
          <!-- <div class="col-12 col-md-6 col-lg-3 res-margin">
            <div class="widget">
              <h6>Suporte</h6>
              <ul class="footer-menu">
                <li><a href="#">FAQ</a></li>
              </ul>
            </div>
          </div> -->

          <!-- Download -->
          <div class="col-12 col-md-6 col-lg-5">
            <div class="widget">
              <h6>Baixar App</h6>

              <div class="button-store">
                <a
                  href="#"
                  class="custom-btn d-inline-flex align-items-center m-2 m-sm-0 mb-sm-3"
                  style="margin-right: 40px"
                  ><i class="fab fa-google-play"></i>
                  <p>Desponivel<span>Google Play</span></p></a
                >
                <a href="#" class="custom-btn d-inline-flex align-items-center m-2 m-sm-0"
                  ><i class="fab fa-apple"></i>
                  <p>Desponivel<span>App Store</span></p></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Text -->
            <p class="copyright text-center">
              Copyright © 2021 <a href="#" target="_blank">Edqea</a>. Todos os direitos reservados.
              | Dsenvolvido por Willsites
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

/* eslint-disable */
declare var $: any;

const Shared = {
  btnSpinner: (status: boolean, options: any) => {
    //this.loadingBar.start();
    const btn: any = <any>document.querySelector(options.btn);
    if (status) {
      btn.setAttribute("disabled", true);
      const spinnerHtml = `<span class="btnSpinner spinner-border
      spinner-border-sm mr-2" role="status" aria-hidden="true"></span>`;

      const img = options.color && options.color == "dark" ? "load-black.svg" : "load-white.svg";

      btn.insertAdjacentHTML("afterbegin", spinnerHtml);
    }

    if (!status) {
      //this.loadingBar.stop();
      btn.removeAttribute("disabled");
      const spinnerElem = <any>document.querySelector(".btnSpinner");
      spinnerElem.remove();
    }
  },

  alert: (error: any, elem: string) => {
    if (error != null) {
      $(elem).addClass("alert-danger");
      $(elem).text(error.error);

      $(elem).fadeIn("slow", function() {
        $(elem)
          .delay(2000)
          .fadeOut("slow");
      });
    }
  }
};

export default Shared;

<script lang="ts">
/* eslint-disabled */
import { defineComponent } from 'vue';
import './app.css';

export default defineComponent({
  name: 'App',
});
</script>

<template>
  <router-view />
</template>

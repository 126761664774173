
/* eslint-disable */
import { defineComponent } from 'vue';

declare var loadSlick: any;

export default defineComponent({
  name: 'Screenshots',
  components: {},
  mounted() {
    loadSlick();
  },
  setup() {
    return {};
  },
});


/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import DriverService from '../../services/DriverService';
import Shared from '@/shared';

import * as FormValidation from '@/shared/FormValidation';
import * as yup from 'yup';
import { configure } from 'vee-validate';

yup.setLocale(FormValidation.locale);
configure(FormValidation.config);

export default defineComponent({
  name: 'Driver',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const register = ref<any>({}) as any;
    const isMsg = ref<boolean>();

    const onSubmit = async (values: any, { resetForm }: any) => {
      Shared.btnSpinner(true, { btn: '.btnLoad' });

      const response = await DriverService.register(values);
      Shared.btnSpinner(false, { btn: '.btnLoad' });
      if (!response.error) resetForm();
      register.value = response;

      isMsg.value = true;
      setTimeout(() => {
        isMsg.value = false;
      }, 3000);
    };

    const formDriver = yup.object({
      name: yup.string().required(),
      email: yup
        .string()
        .email()
        .required(),
      phone: yup.string().required(),
    });
    return { formDriver, onSubmit, register, isMsg };
  },
});

/* eslint-disable */
import axios from "axios";

export const http = axios.CancelToken.source();
const api = axios.create({
  baseURL: "https://api.edqea.com/ed-taxi/api"
  //baseURL: "http://127.0.0.1:8000/api"
});

// Add a request interceptor
api.interceptors.request.use(
  async function(config) {
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log("Ocorreu um erro tente mais tarde.")
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;

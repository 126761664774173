<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';
import Slide from '../components/Slide.vue';
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';
import Driver from '../components/driver/Driver.vue';
import Screenshots from '../components/Screenshots.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    Footer,
    Slide,
    Screenshots,
    Driver,
  },
});
</script>

<template>
  <Header />
  <Slide />
  <section id="features" class="bg-grey">
    <!-- Container -->
    <div class="container">
      <!-- Section title -->
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-6">
          <div class="section-title text-center">
            <h3>Recursos do aplicativo</h3>
            <p>
              Sed laoreet diam sagittis tempus convallis. Interdum et malesuada fames ac ante ipsum
              primis in faucibus.
            </p>
          </div>
        </div>
      </div>

      <div class="row d-flex align-items-center">
        <!-- Left -->
        <div class="col-12 col-md-6 col-lg-4">
          <ul class="features-item">
            <!-- Feature box -->
            <li>
              <div class="feature-box box-left media">
                <!-- Box icon -->
                <div class="box-icon">
                  <div class="icon icon-basic-gear"></div>
                </div>

                <!-- Box Text -->
                <div class="box-text media-body align-self-center align-self-md-start">
                  <h4>Confiavel</h4>
                  <p>
                    Garantimos transparência e excelência no nosso trabalho.
                  </p>
                </div>
              </div>
            </li>

            <!-- Feature box -->
            <li>
              <div class="feature-box box-left media">
                <!-- Box icon -->
                <div class="box-icon">
                  <div class="icon icon-basic-lock"></div>
                </div>

                <!-- Box Text -->
                <div class="box-text media-body align-self-center align-self-md-start">
                  <h4>Cómodo</h4>
                  <p>
                    Viage com conforto, e acomadação. Sinta-se vip.
                  </p>
                </div>
              </div>
            </li>

            <!-- Feature box -->
            <li>
              <div class="feature-box box-left media">
                <!-- Box icon -->
                <div class="box-icon">
                  <div class="icon icon-basic-message-txt"></div>
                </div>

                <!-- Box Text -->
                <div class="box-text media-body align-self-center align-self-md-start">
                  <h4>Rápido e simples</h4>
                  <p>
                    Os nossos motorístas são compremetidos, garantimos um atendimento rápido sem
                    enrolação.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!-- Center -->
        <div class="col-12 col-lg-4 d-none d-lg-block">
          <div class="features-thumb text-center">
            <img src="../assets/features/awesome-features.png" alt="" />
          </div>
        </div>

        <!-- Right -->
        <div class="col-12 col-md-6 col-lg-4">
          <ul class="features-item">
            <!-- Feature box -->
            <li>
              <div class="feature-box media">
                <!-- Box icon -->
                <div class="box-icon">
                  <div class="icon icon-basic-share"></div>
                </div>

                <!-- Box Text -->
                <div class="box-text media-body align-self-center align-self-md-start">
                  <h4>Rastreio de rotas</h4>
                  <p>
                    Recomendamos o caminho mais rápido para a sua viagem.
                  </p>
                </div>
              </div>
            </li>

            <!-- Feature box -->
            <li>
              <div class="feature-box media">
                <!-- Box icon -->
                <div class="box-icon">
                  <div class="icon icon-basic-sheet-multiple"></div>
                </div>

                <!-- Box Text -->
                <div class="box-text media-body align-self-center align-self-md-start">
                  <h4>Estimativa de preço</h4>
                  <p>
                    Saiba mais ou menos quanto irá custar a sua viagem.
                  </p>
                </div>
              </div>
            </li>

            <!-- Feature box -->
            <li>
              <div class="feature-box media">
                <!-- Box icon -->
                <div class="box-icon">
                  <div class="icon icon-basic-alarm"></div>
                </div>

                <!-- Box Text -->
                <div class="box-text media-body align-self-center align-self-md-start">
                  <h4>Seguro</h4>
                  <p>
                    Temos motorístas qualificados, e bem treinados.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <Screenshots />

  <Driver />

  <Footer />
</template>

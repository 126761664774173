
/* eslint-disable */
import { defineComponent } from 'vue';
import Slide from '../components/Slide.vue';
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';
import Driver from '../components/driver/Driver.vue';
import Screenshots from '../components/Screenshots.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    Footer,
    Slide,
    Screenshots,
    Driver,
  },
});

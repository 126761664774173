<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

declare var loadSlick: any;

export default defineComponent({
  name: 'Screenshots',
  components: {},
  mounted() {
    loadSlick();
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
.screenshot-slider .item {
  padding: 0 10px;
}
.screenshot-slider .item img {
}
</style>

<template>
  <section id="screenshots" class="bg-grey">
    <!-- Container -->
    <div class="container">
      <!-- Section title -->
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-6">
          <div class="section-title text-center">
            <h3>Screenshots</h3>
            <p>
              Morbi velit leo, sodales in purus eu, pretium accumsan nunc. Praesent eu diam ut ante
              consequat euismod.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <!-- Carousel -->
          <div class="screenshot-slider zoom-screensho">
            <div class="item">
              <a href="../assets/screenshots/screenshot-1.jpg">
                <img src="../assets/screenshots/screenshot-1.jpg" alt="" />
              </a>
            </div>

            <div class="item">
              <a href="../assets/screenshots/screenshot-2.jpg">
                <img src="../assets/screenshots/screenshot-2.jpg" alt="" />
              </a>
            </div>

            <div class="item">
              <a href="../assets/screenshots/screenshot-3.jpg">
                <img src="../assets/screenshots/screenshot-3.jpg" alt="" />
              </a>
            </div>

            <div class="item">
              <a href="../assets/screenshots/screenshot-4.jpg">
                <img src="../assets/screenshots/screenshot-4.jpg" alt="" />
              </a>
            </div>

            <div class="item">
              <a href="../assets/screenshots/screenshot-5.jpg">
                <img src="../assets/screenshots/screenshot-5.jpg" alt="" />
              </a>
            </div>

            <div class="item">
              <a href="../assets/screenshots/screenshot-6.jpg">
                <img src="../assets/screenshots/screenshot-6.jpg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import DriverService from '../../services/DriverService';
import Shared from '@/shared';

import * as FormValidation from '@/shared/FormValidation';
import * as yup from 'yup';
import { configure } from 'vee-validate';

yup.setLocale(FormValidation.locale);
configure(FormValidation.config);

export default defineComponent({
  name: 'Driver',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const register = ref<any>({}) as any;
    const isMsg = ref<boolean>();

    const onSubmit = async (values: any, { resetForm }: any) => {
      Shared.btnSpinner(true, { btn: '.btnLoad' });

      const response = await DriverService.register(values);
      Shared.btnSpinner(false, { btn: '.btnLoad' });
      if (!response.error) resetForm();
      register.value = response;

      isMsg.value = true;
      setTimeout(() => {
        isMsg.value = false;
      }, 3000);
    };

    const formDriver = yup.object({
      name: yup.string().required(),
      email: yup
        .string()
        .email()
        .required(),
      phone: yup.string().required(),
    });
    return { formDriver, onSubmit, register, isMsg };
  },
});
</script>

<style scoped>
.parallax {
  background-image: url('../../assets/bg-slider.png');
}
</style>

<template>
  <section id="drivers" class="parallax">
    <!-- Overlay -->
    <div class="overlay"></div>

    <!-- Container -->
    <div class="container">
      <!-- Section title -->
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-6">
          <div class="section-title text-center white">
            <h3 class="text-white">Seja um motorísta</h3>
            <p>
              Torne-se uma motorísta Ed-taxi, e começe a facturar a todo dia sem parar.
            </p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
          <Form
            class="contact-form"
            @submit="onSubmit"
            :validation-schema="formDriver"
            v-slot="{ errors }"
          >
            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="form-group mt-2">
                  <Field
                    as="input"
                    type="text"
                    name="name"
                    class="form-control field-name"
                    placeholder="Nome"
                    :class="{ invalid: errors.name, valid: !errors.name }"
                    required
                  />
                  <ErrorMessage name="name" as="span" class="text-danger" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="form-group mt-2">
                  <Field
                    type="email"
                    name="email"
                    class="form-control field-email"
                    placeholder="Email"
                    required
                    :class="{ invalid: errors.email, valid: !errors.email }"
                  />
                  <ErrorMessage name="email" as="span" class="text-danger" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="form-group mt-2">
                  <Field
                    type="text"
                    name="phone"
                    class="form-control field-subject"
                    placeholder="Telefone"
                    :class="{ invalid: errors.phone, valid: !errors.phone }"
                    required
                  />
                  <ErrorMessage name="phone" as="span" class="text-danger" />
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-lg-12 mt-2">
                <button type="submit" name="send" class="btn w-100 btnLoad">
                  Registar-se
                </button>
              </div>
            </div>

            <div class="row mt-4 justify-content-center" v-if="isMsg">
              <div class="col-12 col-lg-12 mt-2">
                <div class="alert alert-danger text-center" v-if="register.error">
                  {{ register.error }}
                </div>
                <div class="alert alert-success text-center" v-if="register.token">
                  A seu registo foi efectuado com sucesso. Entraremos em contacto brevemente para
                  acertarmos alguns detalhes da sua conota.
                </div>
              </div>
            </div>
          </Form>

          <!-- Submit Results -->
          <!-- <div class="contact-form-result">
            <h4>Thank you for the e-mail!</h4>
            <p>Your message has already arrived! We'll contact you shortly.</p>
          </div> -->
        </div>
      </div>

      <!-- Newsletter form -->
    </div>
  </section>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
});
</script>

<template>
  <header id="top-page" class="header">
    <!-- Main menu -->
    <div id="mainNav" class="main-menu-area animated">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-lg-2 d-flex justify-content-between align-items-center">
            <!-- Logo -->
            <div class="logo">
              <h3 class="logo-text">Ed-Tax</h3>
              <!-- <a class="navbar-brand navbar-brand1" href="image.html">
                <img src="../assets/logo-white.png" alt="Naxos" data-rjs="2" />
              </a>

              <a class="navbar-brand navbar-brand2" href="image.html">
                <img src="../assets/logo.png" alt="Naxos" data-rjs="2" />
              </a> -->
            </div>

            <!-- Burger menu -->
            <div class="menu-bar d-lg-none">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div
            class="op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end"
          >
            <!-- Mobile menu -->
            <div class="m-menu-header d-flex justify-content-between align-items-center d-lg-none">
              <!-- Logo -->
              <h3 class="text-dark">Ed-Tax</h3>
              <!--
              <a href="#" class="logo">
                <img src="../assets/logo.png" alt="Naxos" data-rjs="2" />
              </a> -->

              <!-- Close button -->
              <span class="close-button"></span>
            </div>

            <!-- Items -->
            <ul class="nav-menu d-lg-flex flex-wrap list-unstyled justify-content-center">
              <li class="nav-item">
                <a class="nav-link js-scroll-trigger active" href="#">
                  <span>Início</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#features">
                  <span>Recursos</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#screenshots">
                  <span>Screenshots</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#drivers">
                  <span>Seja um motorísta</span>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#footer">
                  <span>Contactos</span>
                </a>
              </li>

              <!-- <li class="nav-item search-option">
                <a class="nav-link" href="#">
                  <i class="fas fa-search"></i>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
